import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
    selector: 'text-directive',
    templateUrl: './text.html',
    styleUrls: ['./text.css']
})

export class Text {
    @Input() module:any;
    text: String = "";

    constructor() {}

    ngOnInit() {
        this.text = this.module.text;
    }
}
