import {Component, EventEmitter, Input, Output} from "@angular/core";
import {Router} from "@angular/router";


@Component({
    selector: 'footer-directive',
    templateUrl: './footer.html',
    styleUrls: ['./footer.css']
})

export class Footer{
    constructor(private router: Router) {

    }

    ngOnInit() {

    }

    redirect(type){
        if(type === 'handelsbetingelser'){
            this.router.navigate(['/handelsbetingelser']);
        }
    }
}