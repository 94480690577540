import {Component} from "@angular/core";
import {SiteService} from "../../services/site.service";

@Component({
    templateUrl: './about.html',
    styleUrls: ['./about.css']
})
export class AboutComponent {
    private pageContent: any;
    loadingSite: boolean = true;
    private domain: string;
    private siteName: string;

    constructor(private siteService: SiteService){
        this.getPageContent();
        window.scrollTo(0,0);
    }

    getPageContent(){
        if(this.siteService.isLoading){
            setTimeout(() => {
                this.getPageContent();
            }, 10);
        }
        else{
            this.pageContent = this.siteService.getPageContentFromLS();
            this.domain = this.pageContent.domain;
            this.siteName = this.pageContent.hostname;
            this.loadingSite = false;
        }
    }
}