// import the required animation functions from the angular animations module
import { trigger, state, animate, transition, style } from '@angular/animations';

export const folderAnimation =
    // trigger name for attaching this animation to an element using the [@triggerName] syntax
    trigger('folderAnimation', [

        state('show', style({
            transform: 'translate3d(0, 0, 0)'
        })),
        state('hide', style({
            transform: 'rotateX(-90deg)',
            display: 'none'
})),
        transition('show => hide', animate('400ms ease-in-out')),
        transition('hide => show', animate('400ms ease-in-out')),
    ]);