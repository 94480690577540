/**
 * Created by Lasse on 07-12-2017.
 */
import {Component, ChangeDetectorRef} from "@angular/core";
import { HttpClient } from '@angular/common/http';
import {ActivatedRoute, Router} from "@angular/router";
import {DomSanitizer} from "@angular/platform-browser";
import {SiteService} from "../../services/site.service";

declare var $: any;

@Component({
    templateUrl: './receipt.html',
    styleUrls: ['./receipt.css']
})
export class ReceiptComponent {
    loadingBuyResponse:boolean=true;
    order:any;
    orderId:string;
    orderSuccess:boolean;
    public receipt:string='';

    constructor(private http: HttpClient, private route: ActivatedRoute){
        this.orderId = this.route.snapshot.params['orderId'];
        this.getOrder();
        window.scrollTo(0,0);
    }


    getOrder(){
        if(this.orderId === 'not_found'){
            this.order = {order_status: 'ORDER_NOT_FOUND_AFTER_PAYMENT'}
        }
        else{
            this.http.get('/api/v1/order/' + this.orderId)
                .subscribe((data) => {
                    this.order = data;
                    console.log(this.order);
                    this.orderSuccess = this.order['order_status'] === 'PAYMENT_COMPLETED';
                    if(!this.orderSuccess){
                        this.loadingBuyResponse = false;
                    }
                    else{
                        this.getReceipt();
                    }
                });
        }
    }

    getReceipt(){
        this.http.get('/api/v1/template/receipt/'+this.orderId, {responseType: 'text'})
            .subscribe((data) => {
                console.log(data);
                this.receipt = data;
                this.loadingBuyResponse = false;
            });
    }

}