import {Component, ChangeDetectorRef, OnInit} from "@angular/core";
import { HttpClient } from '@angular/common/http';
import {ActivatedRoute, Router} from "@angular/router";
import {DomSanitizer} from "@angular/platform-browser";
import {SiteService} from "../../services/site.service";

declare var $: any;

@Component({
    templateUrl: './ticket.html',
    styleUrls: ['./ticket.css']
})
export class TicketComponent implements OnInit{
    private loadingItem: boolean = false;
    private loadingBuyResponse: boolean;
    public item: any;
    state: number = 0;
    private discountCouponCode: string;
    private discountCoupons: any[] = [];
    private codes: string[] = [];
    private number_of_seats: number = 0;
    private validCode: boolean;
    private invalidCode: boolean;
    private eventPlaces: any[] = [];
    private eventPlaceList: Object[] = [
        {name: "Vælg biograf", value:0}
    ];
    private numberOfSeatsList: Object[] = [
        {name: "Vælg antal pladser", value:0}
    ];
    private loadingEventPlace: boolean = false;
    private domain: string;
    private seatingUrl: any;
    private seats: any;
    private order: any;
    private userInputs: any = {};
    private reservationId: string;
    private pageContent: any;
    private seatingHost: string;
    private validateErrorMessage: string;
    private userInputsValid:any={
        name:true,
        email:true,
        msisdn:true,
        age:true,
        address:true,
        zip:true,
        gender:true,
        partner: true,
        matasNumber:true
    };
    loadingSite: boolean = true;
    private selectedEventPlaceList: Object = this.eventPlaceList[0];
    private selectedNumberOfSeats: Object = this.numberOfSeatsList[0];
    private siteName: string;
    selectedPartner:any;
    showEventPlace: boolean = false;
    private orderId: string;
    itemPrice: number;
    public loadingOrder:boolean=false;
    private mobileId:number;
    public cinema:string;
    public reservationIsNotActive: boolean = false;

    constructor(private router: Router, private http: HttpClient, private route: ActivatedRoute, private siteService: SiteService, private sanitizer: DomSanitizer, private chRef: ChangeDetectorRef){
        let orderCompleteState = this.route.snapshot.params['orderCompleteState'];
        this.cinema = this.route.snapshot.params['cinema'];
        console.log('cinema: ' + this.cinema + ', orderCompleteState: ' + orderCompleteState);

        let callbackUrl = () =>{
            if(orderCompleteState){
                this.getItem(5);
            }
            else if(this.cinema){
                this.getItem(2);
            }
            else{
                this.getItem(1);
            }
            this.setNumberOfSeatsList();
        };

        this.getPageContent(callbackUrl);

        // possibel iframe solution: https://gist.github.com/pbojinov/8965299
        window['joinedScope'] = undefined;
        window['setScope'] = function(scope){
            window['joinedScope'] = scope;
        };

        window['seatIsReserved'] = function(seats){
            // if needed to detect ie 11> - https://codepen.io/gapcode/pen/vEJNZN
            window['joinedScope'].seats = seats;
            let myCustomEvent = null;
            if(typeof(Event) === 'function') {
                myCustomEvent = new Event('seatReserved');
            }else{
                myCustomEvent = document.createEvent('Event');
                myCustomEvent.initEvent('seatReserved', true, true);
            }
            document.dispatchEvent(myCustomEvent);
        };

        window['cancleReservation'] = function(){

            let myCustomEvent = null;
            if(typeof(Event) === 'function') {
                myCustomEvent = new Event('cancelReservation');
            }else{
                myCustomEvent = document.createEvent('Event');
                myCustomEvent.initEvent('cancelReservation', true, true);
            }
            document.dispatchEvent(myCustomEvent);
        };

        window['setScope'](this);
    }

    private seatIsReservered: any;
    private cancelReservation: any;
    public dibs:any = {
        'merchant': '',
        'amount': '',
        'accepturl' : '',
        'orderid': '',
        'currency': '',
        'md5key': '',
        'test': ''
    };
    public epay:any = {
        'merchant': '',
        'amount': '',
        'accepturl' : '',
        'cancelurl': '',
        'orderid': '',
        'currency': '',
        'md5key': ''
    };

    ngOnInit() {
        window.scrollTo(0,0);
        var scope = this;
        this.seatIsReservered = function(){
            $('#chooseSeats').modal('hide');
            scope.showEventPlace = false;
            scope.next();
        };
        this.cancelReservation = function(){
            scope.showEventPlace = false;
        }
        document.addEventListener( 'seatReserved', this.seatIsReservered, false);
        document.addEventListener( 'cancelReservation', this.cancelReservation, false);


    }

    ngOnDestroy(){
        document.removeEventListener('seatReserved', this.seatIsReservered, false);
        document.removeEventListener('cancelReservation', this.cancelReservation, false);
        if(this.reservationId && this.state != 4){
            this.removeSeatingReservation();
        }
    }

    private setEventPlaceByCinema(){
        //this.selectedEventPlaceList
        console.log('setEventPlaceByCinema');
        for(let i=0;i<this.item.event_places.length;i++){
            console.log(this.item.event_places[i]);
            if(this.item.event_places[i].url_name === this.cinema){
                console.log('FOUND');
                this.selectedEventPlaceList = {
                    name: this.item.event_places[i].event_place_name,
                    value: this.item.event_places[i].voucher_product_id
                };
                return;
            }
        }
    }
    
    getPageContent(callbackUrl){
        if(this.siteService.isLoading){
            setTimeout(() => {
                this.getPageContent(callbackUrl);
            }, 10);
        }
        else{
            this.pageContent = this.siteService.getPageContentFromLS();
            if(!this.pageContent){
                this.getPageContent(callbackUrl);
            }
            this.loadingSite = false;
            this.setDomainsAndUrls();
            callbackUrl();
        }
    }

    setDomainsAndUrls(){
        this.domain = this.pageContent.domain;
        this.siteName = this.pageContent.hostname;

        if(this.domain == 'localhost'){
            this.seatingHost = 'localhost:9004';
        }
        else{
            this.seatingHost = 'seeding.' + this.domain;
        }
    }

    getItem(state){
        this.loadingItem = true;
        this.http.get('/api/v1/item/' + this.route.snapshot.params['movieName']+'/'+this.pageContent.hostname)
            .subscribe((data) => {
                this.item = data;
                if(state === 1){
                    this.setEventPlaces();
                }
                else if(state === 2 && this.cinema){
                    this.setEventPlaces();
                    this.setEventPlaceByCinema();
                }
                this.validateAllNessecary();
                this.loadingItem = false;
                this.state = state;
                if(this.item.user_input_fields.partner){
                    this.item.user_input_fields.partners.unshift('Vælg partner');
                    this.selectedPartner = this.item.user_input_fields.partners[0];
                }
            });
    }

    setNumberOfSeatsList(){
        this.numberOfSeatsList.push({value: 1, name: 1});
        this.numberOfSeatsList.push({value: 2, name: 2});
        this.numberOfSeatsList.push({value: 3, name: 3});
        this.numberOfSeatsList.push({value: 4, name: 4});
        this.numberOfSeatsList.push({value: 5, name: 5});
        this.numberOfSeatsList.push({value: 6, name: 6});
        /*
        this.numberOfSeatsList.push({value: 7, name: 7});
        this.numberOfSeatsList.push({value: 8, name: 8});
        this.numberOfSeatsList.push({value: 9, name: 9});
        this.numberOfSeatsList.push({value: 10, name: 10});
        */
    }

    setEventPlaces(){
        for(var i=0;i<this.item.event_places.length;i++){
            this.eventPlaces.push({
                productId: this.item.event_places[i].voucher_product_id,
                name: this.item.event_places[i].event_place_name
            })
        }

        for(var i=0;i<this.eventPlaces.length;i++){
            this.eventPlaceList.push({
                name: this.eventPlaces[i].name,
                value: this.eventPlaces[i].productId
            });
        }
    }

    next(){
        let scrollToYValue = 0;

        if(this.state === 1){
            if(this.selectedEventPlaceList['value'] === 0){
                return;
            }
        }

        this.state += 1;

        if(this.state == 2){
            this.showEventPlace = false;
            this.selectedNumberOfSeats = this.numberOfSeatsList[0];
            this.chRef.detectChanges();
            scrollToYValue = Math.ceil($('#select-seats').offset().top);
        }
        else if(this.state == 3){
            this.validateAllNessecary();
            this.chRef.detectChanges();
            scrollToYValue = Math.ceil($('#enter-info').offset().top);
        }
        else if(this.state == 4){
            this.itemPrice = this.item.price * this.selectedNumberOfSeats['value'];
            this.order = {
                seats: this.seats,
                name: this.userInputs.name,
                email: this.userInputs.email,
                msisdn: this.userInputs.msisdn,
                adress: this.userInputs.adress,
                zip: this.userInputs.zip,
                productId: this.selectedEventPlaceList['value'],
                eventPlaceName: this.selectedEventPlaceList['name'],
                partner: this.selectedPartner
            }
            this.userInputs.partner = this.selectedPartner;
            this.chRef.detectChanges();
            scrollToYValue = Math.ceil($('#enter-discount-coupon').offset().top);
        }
        console.log('scrollToYValue: ' + scrollToYValue);
        window.scrollTo(0,scrollToYValue);
    }

    doesCodeExists(code){
        var found = false;
        for(var i=0;i<this.discountCoupons.length;i++){
            if(this.discountCoupons[i].code === code){
                found = true;
                break;
            }
        }
        return found;
    }

    doesCodeProductMatchItemProduct(codeProducts){
        var match = false;
        /*
        for(var i=0;i<this.item.event_places.length;i++){
            for(var j=0;j<codeProducts.length;j++){
                console.log(this.item.event_places[i].voucher_product_id + ' === ' + codeProducts[j].product_id + ' ~ ' + (this.item.event_places[i].voucher_product_id ===codeProducts[j].product_id));
                if(this.item.event_places[i].voucher_product_id ===codeProducts[j].product_id){
                    match = true;
                    break;
                }
            }
            if(match){
                break;
            }
        }
        */

        for(var i=0;i<codeProducts.length;i++){
            console.log(this.selectedEventPlaceList['value'] + ' === ' + codeProducts[i].product_id + ' ~ ' + (this.selectedEventPlaceList['value'] ===codeProducts[i].product_id));
            if(this.selectedEventPlaceList['value'] === codeProducts[i].product_id){
                match = true;
                break;
            }
        }

        console.log(match);
        return match;
    }

    updateEventPlaces(codeProducts){
        var tmpEventPlaces = [];

        for(var j=0;j<codeProducts.length;j++){
            for(var i=0;i<this.eventPlaces.length;i++){
                if(this.eventPlaces[i].productId === codeProducts[j].product_id){
                    tmpEventPlaces.push({
                        productId: this.eventPlaces[i].productId,
                        name: this.eventPlaces[i].name
                    });
                    break;
                }
            }
        }
        this.eventPlaces = tmpEventPlaces;
    }

    setNewReservation(){
        if(this.selectedNumberOfSeats['value'] === 0){
            console.log('not valid');
            return;
        }


        this.loadingEventPlace = true;
        var url = '/voucherapi/v1/seeding/get_new_reservation'
        var data = {
            product_id: this.selectedEventPlaceList['value'],
            number_of_seats: this.selectedNumberOfSeats['value']
        };

        //new_reservation_by_discount_coupons post product_id, discount_coupons
        this.http.post(url, data)
            .subscribe((data) => {
                let json = data;
                let protocol = 'https://';
                if(this.domain == 'localhost'){
                    protocol = 'http://';
                }

                let url = protocol+this.seatingHost+'/#/' + json['reservation_id'] + '/' + this.domain + '/false';
                this.seatingUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                this.reservationId = json['reservation_id'];
                this.loadingEventPlace = false;
                //$("#chooseSeats").modal()
                this.showEventPlace = true;
            },
            (error) =>{

            });
    }

    validateAllNessecary(){
        if(this.item.user_input_fields.name_required){
            this.isNameValid();
        }
        if(this.item.user_input_fields.email_required){
            this.isEmailValid();
        }
        if(this.item.user_input_fields.msisdn_required){
            this.isMsisdnValid();
        }
        if(this.item.user_input_fields.age_required){
            this.isAgeValid();
        }
        if(this.item.user_input_fields.address_required){
            this.isAddressValid();
            this.isZipValid();
        }
        if(this.item.user_input_fields.partner_required){
            this.isPartnerValid();
        }
        if(this.item.user_input_fields.matas_number_required){
            this.isMatasNumberValid();
        }
    }

    isNameValid(){
        setTimeout(() => {
            this.userInputsValid.name = !(this.item.user_input_fields.name_required && (!this.userInputs.name || this.userInputs.name.length === 0));
        }, 10);
    }

    isEmailValid(){
        setTimeout(() => {
            //var EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
            var EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            this.userInputsValid.email = !(this.item.user_input_fields.email_required && (!this.userInputs.email || this.userInputs.email.length <= 5 || !EMAIL_REGEXP.test(this.userInputs.email)));
        }, 10);
    }

    isMsisdnValid(){
        setTimeout(() => {
            this.userInputsValid.msisdn = !(this.item.user_input_fields.msisdn_required && (!this.userInputs.msisdn || this.userInputs.msisdn *1 != this.userInputs.msisdn || (this.userInputs.msisdn+'').length !== 8));
        }, 10);
    }

    isAgeValid(){
        setTimeout(() => {
            this.userInputsValid.age = !(this.item.user_input_fields.age_required && (!this.userInputs.age || this.userInputs.age *1) != this.userInputs.age);
        }, 10);
    }

    isAddressValid(){
        setTimeout(() => {
            this.userInputsValid.address = !(this.item.user_input_fields.address_required && (!this.userInputs.address || this.userInputs.address.length === 0));
        }, 10);
    }

    isZipValid(){
        setTimeout(() => {
            this.userInputsValid.zip = !(this.item.user_input_fields.address_required && !this.userInputs.zip);
        }, 10);
    }

    isPartnerValid(){
        setTimeout(() => {
            this.userInputsValid.partner = !(this.item.user_input_fields.partner_required && this.selectedPartner === 'Vælg partner');
        }, 10);
    }

    public isMatasNumberValid(){
        setTimeout(() => {
            if(this.item.user_input_fields.matas_number_required && !this.userInputs.matas_number){
                this.userInputsValid.matasNumber = false;
                return;
            }

            if(!this.item.user_input_fields.matas_number_validation){
                this.userInputsValid.matasNumber = true;
                return;
            }

            if(this.userInputs.matas_number){
                let tmpMatasId = this.userInputs.matas_number;
                if(this.userInputs.matas_number * 1 === this.userInputs.matas_number){
                    let url = '/klubpointapi/v1/wundermann/matas/'+this.userInputs.matas_number;
                    setTimeout(() => {
                            if (tmpMatasId !== '' && tmpMatasId === this.userInputs.matas_number) {
                                this.http.get(url)
                                    .subscribe((data) =>{
                                            let json = data;
                                            this.userInputsValid.matasNumber = json['isMatasId'];
                                        },
                                        (error) =>{
                                            this.userInputsValid.matasNumber = false;
                                        }
                                    );
                            }
                        }
                        , 500);
                }
                else{
                    this.userInputsValid.matasNumber = false;
                }
            }

        }, 10);
    }

    formValid(){
        return this.userInputsValid.name &&
            this.userInputsValid.email &&
            this.userInputsValid.msisdn &&
            this.userInputsValid.age &&
            this.userInputsValid.address &&
            this.userInputsValid.zip &&
            this.userInputsValid.gender &&
            this.userInputsValid.partner &&
            this.userInputsValid.matasNumber;
    }



    back(){
        if(this.state === 1){
            this.router.navigate(['']);
        }
        var scrollToYValue = 0;
        this.state-=1;
        if(this.state === 1){
            this.chRef.detectChanges();
            scrollToYValue = Math.ceil($('#select-event-place').offset().top);
        }
        else if(this.state === 2){
            this.removeSeatingReservation();
            this.chRef.detectChanges();
            scrollToYValue = Math.ceil($('#select-seats').offset().top);
        }
        else if(this.state === 3){
            this.chRef.detectChanges();
            scrollToYValue = Math.ceil($('#enter-info').offset().top);
        }
        window.scrollTo(0,scrollToYValue);
    }

    backToStart(){
        // undo reservation
        this.removeSeatingReservation();

        // remove already entered discount coupons
        this.number_of_seats = 0;

        // reset discount coupons
        this.discountCoupons = [];

        // reset state
        this.state = 1;
    }

    removeSeatingReservation(){
        var url = '/voucherapi/v1/seeding/remove_reservation/'+this.selectedEventPlaceList['value']+'/'+this.reservationId;
        this.http.delete(url)
            .subscribe((data) => {
                console.log('removed last reservation - success');
            },
            (error) =>{
                console.log('removed last reservation - failed');
            });
    }

    validateCode(){
        var self = this;
        this.loadingOrder = true;
        this.discountCouponCode = this.discountCouponCode.toLowerCase();
        this.http.get('/voucherapi/v1/discount-coupons/validate_discount_coupon/'+ this.discountCouponCode)
            .subscribe((data) => {
                    var json = data;
                    var exists = this.doesCodeExists(json['code']);
                    var match = this.doesCodeProductMatchItemProduct(json['products']);

                    console.log('TEST data start');
                    console.log(exists);
                    console.log(this.eventPlaces.length === 0);
                    console.log('TEST data end');
                    if(!match){
                        this.invalidCode = true;
                        this.validCode = false;
                        this.validateErrorMessage = 'Koden er ikke gyldig til denne forstilling';
                        this.loadingOrder = false;
                        return;
                    }

                    // update eventPlaces
                    if(!exists || match){
                        this.updateEventPlaces(json['products']);
                    }

                    if(exists || this.eventPlaces.length === 0){
                        this.invalidCode = true;
                        this.validCode = false;
                        this.validateErrorMessage = 'Koden er ikke gyldig';
                        this.loadingOrder = false;
                    }
                    else{
                        this.discountCoupons.push(json);
                        this.invalidCode = false;
                        this.validCode = true;
                        this.number_of_seats=0;
                        for(var i=0; i<this.discountCoupons.length; i++){
                            this.number_of_seats += this.discountCoupons[i].seats;
                            this.codes.push(this.discountCoupons[i].code);
                        }
                        this.validateErrorMessage = '';
                    }

                    if(this.validCode){
                        // Check if and order has been created, if not create one
                        if(this.orderId){
                            // add discount coupon to order
                            self.addDiscountCouponToOrder(self.discountCouponCode);
                        }
                        else{
                            // create a new order
                            this.createOrder(
                                function(data){
                                    self.orderId = data['order_id'];
                                    // add discount coupon to order
                                    self.addDiscountCouponToOrder(self.discountCouponCode);

                                },
                                function(){

                                });
                        }
                    }
                    $(".faded").first().removeClass("faded");
                },
                (error) =>{
                    this.invalidCode = true;
                    this.validCode = false;
                    this.validateErrorMessage = 'Koden er ikke gyldig';
                    this.loadingOrder = false;
                });
    }

    payByDiscountCoupons(){
        this.loadingOrder = true;
        var url = '/api/v1/order/pay_by_discount_coupons';
        var data = {
            order_id: this.orderId
        };
        this.http.put(url, data)
            .subscribe((data) => {
                    var json = data;
                    this.loadingOrder = false;
                    this.router.navigate(['/kvittering/'+json['order_id']]);
                },
                (error) =>{
                    this.loadingOrder = false;
                });

    }

    payByDibs(){
        this.loadingBuyResponse = true;
        var self = this;
        if(this.orderId){
            self.setDibsPaymentInfo(function(data){
                self.dibs = {
                    'merchant': data['merchant'],
                    'amount': data['amount'],
                    'accepturl' : data['accepturl'],
                    'orderid': data['orderid'],
                    'currency': data['currency'],
                    'md5key': data['md5key'],
                    'test': data['test']
                };
                self.loadingBuyResponse = false;
                if(data['test'] != 1){
                    $( "#test" ).remove();
                }
                self.chRef.detectChanges();
                $('#dibsPost').submit();
            });
        }
        else{
            this.createOrder(
                function(data){
                    self.orderId = data['order_id'];
                    self.setDibsPaymentInfo(function(data){
                        self.dibs = {
                            'merchant': data['merchant'],
                            'amount': data['amount'],
                            'accepturl' : data['accepturl'],
                            'orderid': data['orderid'],
                            'currency': data['currency'],
                            'md5key': data['md5key'],
                            'test': data['test']
                        };
                        self.loadingBuyResponse = false;
                        if(data['test'] != 1){
                            $( "#test" ).remove();
                        }
                        self.chRef.detectChanges();
                        $('#dibsPost').submit();
                    });
                },
                function(){
                    this.loadingBuyResponse = false;
                });
        }
    }


    payByEPay(){
        this.loadingBuyResponse = true;

        // Check here if the reservation is still available
        this.http.get('/api/v1/order/is_reservation_id_still_active/'+this.reservationId)
            .subscribe( (data)=>{
                let active = data['active'] as boolean;
                if(active){
                    this.payByEPayCreateOrder();
                }
                else{
                    this.reservationIsNotActive = true;
                    this.loadingBuyResponse = false;
                }
            },
            (data)=>{
                this.reservationIsNotActive = true;
                this.loadingBuyResponse = false;
            });



    }

    public payByEPayCreateOrder(){
        let self = this;
        if(this.orderId){
            self.setEpayPaymentInfo(function(data){
                self.epay = {
                    'merchant': data['merchant'],
                    'amount': data['amount'],
                    'accepturl': data['accepturl'],
                    'orderid': data['orderid'],
                    'currency': data['currency'],
                    'md5key': data['md5key']
                };
                self.loadingBuyResponse = false;
                self.chRef.detectChanges();
                $('#epayPost').submit();
            });
        }
        else{
            this.createOrder(
                function(data){
                    self.orderId = data['order_id'];
                    self.setEpayPaymentInfo(function(data){
                        self.epay = {
                            'merchant': data['merchant'],
                            'amount': data['amount'],
                            'accepturl': data['accepturl'],
                            'orderid': data['orderid'],
                            'currency': data['currency'],
                            'md5key': data['md5key']
                        };
                        self.loadingBuyResponse = false;
                        self.chRef.detectChanges();
                        $('#epayPost').submit();
                    });
                },
                function(){
                    this.loadingBuyResponse = false;
                });
        }
    }


    addDiscountCouponToOrder(discountCoupon){
        var url = '/api/v1/order/add_discount_coupon_to_order';
        var data = {
            order_id: this.orderId,
            discount_coupon: discountCoupon,
            item_id: this.item['_id']
        };
        this.http.put(url, data)
            .subscribe((data) => {
                var json = data;
                this.discountCouponCode = '';
                this.itemPrice = json['order_price'];
                if(this.selectedNumberOfSeats['value'] === this.number_of_seats){
                    this.payByDiscountCoupons();
                }
                else{
                    this.loadingOrder = false;
                }
            },
            (error) =>{
                this.discountCouponCode = '';
                this.loadingOrder = false;
            });
    }

    /*
    orderSeats(){
        this.loadingBuyResponse = true;

        var url = '/api/v1/order';
        if(this.userInputs.msisdn){
            this.userInputs.msisdn+='';
        }
        var data = {
            user_inputs: this.userInputs,
            site_id: this.item.site_id,
            basket: [
                {
                    item_name: this.item.header_text,
                    item_id: this.item._id,
                    discount_coupons: this.codes,
                    seats: this.seats,
                    product: {
                        eventPlaceName: this.order.eventPlaceName,
                        product_id: this.order.productId,
                        amount: this.seats.length,
                        reservation_id: this.reservationId
                    }
                }
            ]
        };

        this.http.post(url, data)
            .subscribe((data) => {
                    var json = data;
                    this.orderNumber = json['order_number'];
                    this.loadingBuyResponse = false;
                },
                (error) =>{
                    this.loadingBuyResponse = false;
                });
    }
    */


    createOrder(callback, callbackFailure) {
        var url = '/api/v1/order/create_order';
        if (this.userInputs.msisdn) {
            this.userInputs.msisdn += '';
        }

        if (this.userInputs.matas_number) {
            this.userInputs.matas_number += '';
        }

        var data = {
            user_inputs: this.userInputs,
            site_id: this.item.site_id,
            basket: [
                {
                    item_name: this.item.header_text,
                    item_id: this.item._id,
                    seats: this.seats,
                    product: {
                        eventPlaceName: this.order.eventPlaceName,
                        product_id: this.order.productId,
                        amount: this.seats.length,
                        reservation_id: this.reservationId
                    }
                }
            ]
        };
        //                    discount_coupons: this.codes,

        this.http.post(url, data)
            .subscribe((data) => {
                var json = data;
                callback(json);
            },
            (error) => {
                callbackFailure();
            });
    }

    setDibsPaymentInfo(callback){
        var url = 'api/v1/order/dibs_info/'+this.orderId;
        this.http.get(url).subscribe((data) => {
            var json = data;
            callback(json);
        });
    }

    setEpayPaymentInfo(callback){
        this.mobileId = window.innerWidth > 800 ? 1: 2;

        let url = 'api/v1/payment/epay_info/'+this.orderId + '/mobile/' + this.mobileId;
        this.http.get(url).subscribe((data) => {
            callback(data);
        });
    }

    eventPlaceInfo(){
        $('#eventPlaceInfo').modal();
    }

    personalInfo(){
        $('#personalInfo').modal();
    }

    codeRedeemInfo(){
        $('#codeRedeemInfo').modal();
    }

    listenOnObject(object){
        console.log('LISTENER');
        console.log(object);
    }

    private onValueChanged(data?: any) {
        console.log(data);
    }


}
