import { NgModule } from '@angular/core';
import {Nav} from "./directives/nav/nav.directive";
import {Footer} from "./directives/footer/footer";

@NgModule({
    imports: [],
    declarations: [
        Footer, Nav
    ],
    exports: [Footer, Nav]
})

export class SharedModule {
    static forRoot(){
        return {
            ngModule: SharedModule,
            providers: []
        }
    }
}