import {Component, EventEmitter, Input, Output, OnInit} from "@angular/core";
import { HttpClient } from '@angular/common/http';

declare var $: any;

@Component({
    selector: 'product-list',
    templateUrl: './product-list.html',
    styleUrls: ['./product-list.css']
})

export class ProductList implements OnInit {
    viewableItems: any[] = [];
    private dummyCounter: number = 0;
    @Input() items: any;

    constructor(private http: HttpClient) {

    }

    ngOnInit() {
        for (var i = 0; i < this.items.length; i++) {
            this.viewableItems.push({
                loading: true,
                id: this.items[i].item_id,
                priority: this.items[i].priority
            });
        }
        this.loadItems();
    }

    loadItems(){
        for(var i=0;i<this.viewableItems.length;i++){
            if(this.viewableItems[i].loading){
                var item = this.viewableItems[i];
                var url = '/api/v1/item/by_id/' + this.viewableItems[i].id;
                this.http.get(url)
                    .subscribe((data) =>{
                            var json = data;
                            item.loading = false;
                            item.image = json['image'];
                            item.name = json['name'];
                            item.urlName = json['url_name'];
                            item.headerText = json['header_text'];
                            item.teaserText = json['teaser_text'];
                            item.trailerLink = json['trailer_link'];
                            item.createdByText = json['created_by_text'];
                            item.canBuy = json['can_buy'];
                            this.loadItems();
                        },
                        (error) =>{
                            item.loading = false;
                        });
                break;
            }
        }
    }
}