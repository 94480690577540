import {Component, EventEmitter, Input, Output} from "@angular/core";
import { HttpClient } from '@angular/common/http';


@Component({
    selector: 'header-directive',
    templateUrl: './header.html',
    styleUrls: ['./header.css']
})

export class Header{
    @Input() module:any;
    @Input() hostname:any;
    public loadingDesktopImage: boolean = true;
    public loadingMobileImage: boolean = true;
    public imageDesktopBase64: string;
    public imageMobileBase64: string;

    constructor(private http: HttpClient) {
    }

    ngOnInit() {
        this.setDesktopImg();
        this.setMobileImg();
    }

    setDesktopImg(){
        this.loadingDesktopImage = true;
        this.http.get('/api/v1/site/image/header/'+ this.hostname+'/image_desktop', {responseType: 'text'})
            .subscribe((data) => {
                this.imageDesktopBase64 = data;
                this.loadingDesktopImage = false;
            },
            (error) =>{
                this.loadingDesktopImage = false;
            });
    }


    setMobileImg(){
        this.loadingMobileImage = true;
        this.http.get('/api/v1/site/image/header/'+ this.hostname+'/image_mobile', {responseType: 'text'})
            .subscribe((data) => {
                    this.imageMobileBase64 = data;
                    this.loadingMobileImage = false;
                },
                (error) =>{
                    this.loadingMobileImage = false;
                });
    }
}