import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SiteService {

    public isLoading: boolean = false;

    constructor(private http: HttpClient){

    }

    downloadPageContent(component){
        this.isLoading = true;
        window.localStorage.removeItem('site');
        this.http.get('/api/v1/site/' + window.location.hostname)
            .subscribe((data) => {
                var json = data;
                window.localStorage.setItem('site', JSON.stringify(json));
                component.showContent(json);
                component.setDomain(json);
                this.isLoading = false;
            });
    }

    getPageContentFromLS(){
        return JSON.parse(window.localStorage.getItem('site'));
    }
}