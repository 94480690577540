import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {FrontComponent} from "./front/front.component";
import {AboutComponent} from "./staticpages/about/about.component";
import {BuisnesstermsComponent} from "./staticpages/buisnessterms/buisnessterms.component";
import {ContactComponent} from "./staticpages/contact/contact.component";
import {HelpComponent} from "./staticpages/help/help.component";
import {CookiesComponent} from "./staticpages/cookies/cookies.component";
import {TicketComponent} from "./order/ticket/ticket.component";
import {SubscribeComponent} from "./staticpages/subscribe/subscribe.component";
import {ReceiptComponent} from "./order/receipt/receipt.component";
import {TestComponent} from "./test/test.component";

const routes: Routes = [
  { path: '', component: FrontComponent},
  { path: 'forpremiere/:movieName', component: TicketComponent},
  { path: 'forpremiere/:movieName/:cinema', component: TicketComponent},
  /*{ path: 'forpremiere/:movieName/:orderCompleteState', component: TicketComponent},*/
  { path: 'om_os', component: AboutComponent},
  { path: 'handelsbetingelser', component: BuisnesstermsComponent},
  { path: 'kontakt', component: ContactComponent},
  { path: 'help', component: HelpComponent},
  { path: 'nyhedsbrev', component: SubscribeComponent},
  { path: 'cookies', component: CookiesComponent},
  { path: 'kvittering/:orderId', component: ReceiptComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
