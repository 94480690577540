import {Component, OnInit } from "@angular/core";
import {SiteService} from "../../services/site.service";
import {folderAnimation} from "../../_animations/index";

@Component({
    templateUrl: './help.html',
    styleUrls: ['./help.css'],
    animations: [folderAnimation]
})
export class HelpComponent{
    private pageContent: any;
    loadingSite: boolean = true;
    private domain: string;
    siteName: string;
    partnerName: string;

    state: string = 'show';

    menus: any = {
        m1: 'hide',
        m2: 'hide',
        m3: 'hide',
        m4: 'hide',
        m5: 'hide',
        m6: 'hide',
        m7: 'hide',
        m8: 'hide',
        m9: 'hide',
    }

    constructor(private siteService: SiteService){
        this.getPageContent();
        window.scrollTo(0,0);
    }

    getPageContent(){
        if(this.siteService.isLoading){
            setTimeout(() => {
                this.getPageContent();
            }, 10);
        }
        else{
            this.pageContent = this.siteService.getPageContentFromLS();
            this.domain = this.pageContent.domain;
            this.siteName = this.pageContent.hostname;
            this.partnerName = this.pageContent.name;
            this.loadingSite = false;
        }
    }
}