import {Component} from "@angular/core";
import {SiteService} from "../../services/site.service";
import {HttpClient} from "@angular/common/http";

//https://stackoverflow.com/questions/39542975/mailchimp-signup-form-with-angular2

@Component({
    templateUrl: './subscribe.html',
    styleUrls: ['./subscribe.css']
})
export class SubscribeComponent {
    private pageContent: any;
    loadingSite: boolean = true;
    private domain: string;
    private siteName: string;
    private partnerName: string;
    userSubscribed:boolean=false;
    userInputs:any = {
        email:'',
        emailValid:false,
        firstName:'',
        firstNameValid:false,
        lastName:'',
        lastNameValid:false
    };
    public loadingRequest: boolean = false;
    public success: boolean;

    constructor(private siteService: SiteService, private http: HttpClient){
        this.getPageContent();
        window.scrollTo(0,0);
    }

    getPageContent() {
        if (this.siteService.isLoading) {
            setTimeout(() => {
                this.getPageContent();
            }, 10);
        }
        else {
            this.pageContent = this.siteService.getPageContentFromLS();
            this.domain = this.pageContent.domain;
            this.siteName = this.pageContent.hostname;
            this.partnerName = this.pageContent.name;
            this.loadingSite = false;
        }
    }

    isEmailValid(){
        setTimeout(() => {
            //var EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
            var EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            this.userInputs.emailValid = this.userInputs.email.length >= 5 && EMAIL_REGEXP.test(this.userInputs.email);
        }, 10);
    }

    isFirstNameValid(){
        setTimeout(() => {
            this.userInputs.firstNameValid = this.userInputs.firstName && this.userInputs.firstName.length > 0;
        }, 10);
    }

    isLastNameValid(){
        setTimeout(() => {
            this.userInputs.lastNameValid = this.userInputs.lastName && this.userInputs.lastName.length > 0;
        }, 10);
    }

    formValid(){
        return this.userInputs.emailValid && this.userInputs.firstNameValid && this.userInputs.lastNameValid;
    }

    subscribeUser(){
        this.loadingRequest = true;
        var url = '/api/v1/mailchimp/subscribe_user'
        var data = {
            email: this.userInputs.email,
            first_name: this.userInputs.firstName,
            last_name: this.userInputs.lastName
        };

        //new_reservation_by_discount_coupons post product_id, discount_coupons
        this.http.post(url, data)
            .subscribe((data) => {
                var json = data;
                this.loadingRequest = false;
                this.userSubscribed=true;
                this.success = (json['result'] == 'success')
            },
            (error) =>{
                this.loadingRequest = false;
                this.userSubscribed = true;
                this.success = false;
            });
    }
}