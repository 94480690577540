import {Component, EventEmitter, Input, Output} from "@angular/core";
import { HttpClient } from '@angular/common/http';
import {Router} from "@angular/router";

@Component({
    selector: 'nav-directive',
    templateUrl: './nav.html',
    styleUrls: ['./nav.css']
})

export class Nav{
    @Input() module:any;
    @Input() hostname:any;
    imageBase64:string;
    loadingImage: boolean=true;

    constructor(private http: HttpClient, private router: Router) {
    }

    setImg(){
        this.loadingImage = true;
        this.http.get('/api/v1/site/image/nav/'+ this.hostname+'/logo', {responseType: 'text'})
            .subscribe((data) => {
                this.imageBase64 = data;
                this.loadingImage = false;
            },
            (error) =>{
                console.log('error', error);
                this.loadingImage = false;
            });
    }

    ngOnInit() {
        this.setImg();
        var self = this;
    }

    openNav() {
        if(document.getElementById("mySidenav").style.width === '250px'){
            this.closeNav();
        }
        else{
            document.getElementById("mySidenav").style.width = "250px";
        }
    }

    closeNav() {
        document.getElementById("mySidenav").style.width = "0";
    }

    goToMenuPoint(page){
        this.closeNav();
        this.router.navigate([page]);
    }

}
// toggle class: https://codepen.io/CreativeJuiz/pen/oCBxz