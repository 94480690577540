import { Component } from '@angular/core';
import {SiteService} from "../services/site.service";

@Component({
    selector: 'my-app',
    templateUrl: './main.html',
    styleUrls: ['./main.css']
})
export class MainComponent {
    public pageContent: any = {};
    showCookieMsg: boolean=false;
    loadingSite:boolean=true;
    siteName:string;


    constructor(private siteService: SiteService) {
        siteService.downloadPageContent(this);
        this.hasUserReadCookies();
    }

    showContent(data){
        document.body.style.backgroundColor = data.background_color;
        this.getPageContent();
    }

    setDomain(data){
        document.domain = data.domain;
    }

    hasUserReadCookies(){
        var seenCookies = JSON.parse(window.localStorage.getItem('seencookies'));
        if(seenCookies && seenCookies.seen){
            this.showCookieMsg = false;
        }
        else{
            this.showCookieMsg = true;
        }
    }

    setUserHasReadCookies(){
        this.showCookieMsg = false;
        window.localStorage.setItem('seencookies', JSON.stringify({'seen': true}));
    }

    getPageContent(){
        if(this.siteService.isLoading){
            setTimeout(() => {
                this.getPageContent();
            }, 10);
        }
        else{
            this.pageContent = this.siteService.getPageContentFromLS();
            this.siteName = this.pageContent.hostname;
            this.loadingSite = false;
        }
    }
}