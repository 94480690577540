import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {LocationStrategy,HashLocationStrategy} from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import {MainComponent} from "./main/main.component";
import {FrontComponent} from "./front/front.component";
import {AboutComponent} from "./staticpages/about/about.component";
import {BuisnesstermsComponent} from "./staticpages/buisnessterms/buisnessterms.component";
import {ContactComponent} from "./staticpages/contact/contact.component";
import {CookiesComponent} from "./staticpages/cookies/cookies.component";
import {HelpComponent} from "./staticpages/help/help.component";
import {SubscribeComponent} from "./staticpages/subscribe/subscribe.component";
import {SortItemsByPrioirtyPipe} from "./pipes/sortItemsByPriorityPipe";
import {TicketComponent} from "./order/ticket/ticket.component";
import { FormsModule } from '@angular/forms';
import {SharedModule} from "./shared.module";
import {SiteService} from "./services/site.service";
import { HttpClientModule } from '@angular/common/http';
import {RdLoading} from "./directives/rd-loading/rd-loading";
import {Header} from "./directives/header/header";
import {Text} from "./directives/text/text.directive";
import {ProductList} from "./directives/product-list/product-list.directive";
import {ReceiptComponent} from "./order/receipt/receipt.component";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {SafePipe} from "./pipes/safe.pipe";
import { CommonModule } from '@angular/common';
import {TestComponent} from "./test/test.component";
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    MainComponent,
    FrontComponent,
    AboutComponent,
    BuisnesstermsComponent,
    ContactComponent,
    CookiesComponent,
    HelpComponent,
    SubscribeComponent,
    RdLoading,
    Header,
    Text,
    ProductList,
    TicketComponent,
    SortItemsByPrioirtyPipe,
    SafePipe,
    ReceiptComponent,
    TestComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CommonModule,
    NgSelectModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}, SiteService, SortItemsByPrioirtyPipe, SafePipe],
  bootstrap: [MainComponent]
})
export class AppModule { }
